import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';

import MenuPreviewItem from './MenuPreviewItem';
import * as MenusStore from 'store/menusStore';
import { actionCreators as ingredientsActions } from 'store/ingredientsStore';
import { ApplicationState } from 'store';
import { MenuCreateModel } from 'api/models';
import { MenuDetails } from 'components/menu/MenusPage/components';
import { images } from 'assets';
import { Roles } from 'constants/enums';
import { useStyles } from '../styles';

interface MenusTableProps {
    isModal?: boolean;
    close?: () => void;
    setMenuName?: (name: string) => void;
    handleDetails?: (id: string) => void;
    showMenuDetails?: boolean;
    setShowMenuDetails?: () => void;
    menuId?: string;
}
export default function MenusTable(props: MenusTableProps) {
    const classes = useStyles();
    const menus = useSelector((state: ApplicationState) => state.menus);
    const lang = useSelector((state: ApplicationState) => state.global.language);
    const ingredients = useSelector((state: ApplicationState) => state.ingredients);
    const user = useSelector((state: ApplicationState) => state.user);
    const dispatch = useDispatch();
    const [renderMenus, setRenderMenus] = useState([...menus.menus]);

    const draggedMenu = useRef<MenusStore.MenuData | null>(null);
    const [draggedMenuId, setDraggedMenuId] = useState('');

    function onDragStart(menu: MenusStore.MenuData) {
        setDraggedMenuId(menu.id);
        draggedMenu.current = menu;
    }

    function onDragEnter(menu: MenusStore.MenuData) {
        if (menu.id !== draggedMenu?.current?.id) {
            const tempMenus = [...renderMenus];
            const removedIndex = tempMenus.indexOf(draggedMenu?.current!);
            if (removedIndex < 0) {
                return;
            }
            const removedMenu = tempMenus.splice(removedIndex, 1);
            const targetMenuIndex = tempMenus.indexOf(menu);
            if (removedIndex === targetMenuIndex) {
                if (removedIndex === 0 && targetMenuIndex === 0) {
                    tempMenus.splice(targetMenuIndex + 1, 0, removedMenu[0]);
                } else if (removedIndex === 1 && targetMenuIndex === 0) {
                    tempMenus.unshift(removedMenu[0]);
                } else {
                    tempMenus.splice(targetMenuIndex + 1, 0, removedMenu[0]);
                }
            } else if (removedIndex > targetMenuIndex) {
                tempMenus.splice(targetMenuIndex, 0, removedMenu[0]);
            } else {
                tempMenus.splice(targetMenuIndex + 1, 0, removedMenu[0]);
            }

            setRenderMenus(tempMenus);
        }
    }

    function onDragEnd() {
        if (
            menus.menus.indexOf(draggedMenu.current!) !== renderMenus.indexOf(draggedMenu.current!)
        ) {
            dispatch(MenusStore.actionCreators.reorderMenus(renderMenus.map((x) => x.id)));
        }
        setDraggedMenuId('');
        draggedMenu.current = null;
    }

    useEffect(() => {
        setRenderMenus([...menus.menus]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menus.menus]);
    useEffect(() => {
        dispatch(MenusStore.actionCreators.getMenus());
        ingredients.ingredients.length === 0 && dispatch(ingredientsActions.getIngredients(lang));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div
            className={classes.menusTableRoot}
            //className={clsx(classes.root, classes.forModal)}
            /* className={clsx(classes.root, {
                [classes.forModal]: props.isModal,
            })} */
        >
            {props.showMenuDetails ? (
                <MenuDetails
                    id={props.menuId}
                    close={() => {
                        !!props.setShowMenuDetails && props.setShowMenuDetails();
                    }}
                    isModal={props.isModal}
                />
            ) : (
                <>
                    {user.role === Roles.OWNER && (
                        <MenuPreviewItem
                            disable={(id: string) =>
                                dispatch(MenusStore.actionCreators.disableMenu(id))
                            }
                            key={'ING'}
                            menuType={'ING'}
                            edit={null}
                            menu={{
                                edit: '',
                                id: 'ii',
                                name: i18n.t('common.ingredients'),
                                description: 'Some',
                                price: 0,
                                photo: images.ingredientsDefault,
                                type: 2,
                                sections: [''],
                                dishesCount: ingredients.ingredientsCount,
                                isEnabled: true,
                                position: 0,
                                availableFrom: 0,
                                availableTo: 0,
                            }}
                            showDetails={props.isModal ? props.handleDetails : undefined}
                            setName={props.setMenuName}
                        />
                    )}
                    {renderMenus.length > 0 &&
                        renderMenus.map((menu, i) => (
                            <div
                                key={menu.id}
                                draggable={user.role === Roles.OWNER}
                                onDragStart={() => onDragStart(menu)}
                                onDragEnter={() => onDragEnter(menu)}
                                onDragEnd={onDragEnd}
                                onDragOver={(e) => e.preventDefault()}
                                style={{
                                    opacity: menu.id === draggedMenuId ? 0.1 : 1,
                                }}
                            >
                                <MenuPreviewItem
                                    disable={(id: string) =>
                                        dispatch(MenusStore.actionCreators.disableMenu(id))
                                    }
                                    key={menu.id}
                                    menuType={''}
                                    edit={(model: MenuCreateModel, id: string) =>
                                        dispatch(MenusStore.actionCreators.editMenu(model, id))
                                    }
                                    menu={menu}
                                    showDetails={props.isModal ? props.handleDetails : undefined}
                                    setName={props.setMenuName}
                                />
                            </div>
                        ))}
                </>
            )}
        </div>
    );
}
