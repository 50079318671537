import React, { useState } from 'react';

import i18n from 'i18n-js';

import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PaletteIcon from '@material-ui/icons/Palette';

import { SectionData, MenuDetailsData } from 'store/menuDetailsStore/reducer';
import { MenuType, Positions } from 'constants/enums';
import { theme } from 'config/theme';
import { PopupConfirmation, ColorSelector } from 'components/shared';
import RenameSection from './RenameSectionMenuItem';
import { AddNewDishItem } from './AddNewDishItem';
import DeleteMenuItem from './DeleteMenuItem';
import DisableEnableSection from './DisableEnableSection';
import EditDailyMenuSection from './EditDailyMenuSection';
import { useAppSelector } from 'index';

//should be refactored and replaced with shared basic three dots menu component
const StyledMenu = withStyles((theme) => ({
    paper: {
        border: '1px solid #d3d4d5',
        '& ul.MuiList-root': {
            padding: 0,
        },
        '& ul > li:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
    },
}))((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

export interface ThreeDotsSectionProps {
    section: SectionData;
    onDeleteSectionClick: () => void;
    onChooseExisting: () => void;
    menu: MenuDetailsData;
    color: string;
    onChangeColor: (color: string) => void;
    onSaveColor: () => void;
    onResetColor: () => void;
}

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minHeight: '48px !important',
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function ThreeDotsSectionMenu(props: ThreeDotsSectionProps) {
    const { menu } = props;
    const user = useAppSelector((state) => state.user);
    const currentRestaurant = user.restaurants?.find((x) => x.id === user.currentRestaurantId);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showColorSelector, setShowColorSelector] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(null);
        event?.stopPropagation();
    };

    function onChooseExistingClick(e: any) {
        setAnchorEl(null);
        e.stopPropagation();
        props.onChooseExisting();
    }

    function handleColorSelector(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(null);
        event.stopPropagation();
        setShowColorSelector(true);
    }

    function handleColorReset() {
        props.onResetColor();
        setShowColorSelector(false);
    }

    function prepareContent() {
        const content = [];

        if (menu.type === MenuType.Daily) {
            if (props.section.dishes.length < 5) {
                content.push(
                    <AddNewDishItem
                        key="add-dish-item"
                        sectionId={props.section.id}
                        sections={props.menu.sections}
                        close={handleClose}
                        forThreeDotsMenu={true}
                        dailyMenuId={props.section.id}
                    />,
                    <StyledMenuItem onClick={onChooseExistingClick} key="chose-dish-item">
                        <FormatListBulletedIcon
                            fontSize="small"
                            color="disabled"
                            style={{ marginRight: 23 }}
                        />
                        <ListItemText primary={i18n.t('common.chooseExisting')} />
                    </StyledMenuItem>
                );
            }
            content.push(
                <EditDailyMenuSection
                    key="edit-menu"
                    section={props.section}
                    onClose={handleClose}
                    menus={props.menu.sections.reduce((result: string[], current) => {
                        if (current.id !== props.section.id) {
                            result.push(current.name.toUpperCase());
                        }
                        return result;
                    }, [])}
                />,
                currentRestaurant?.currentPosition === Positions.MANAGER ? (
                    <StyledMenuItem onClick={handleColorSelector} key="set-color">
                        <PaletteIcon
                            fontSize="small"
                            color="disabled"
                            style={{ marginRight: 23 }}
                        />
                        <ListItemText primary={i18n.t('common.changeColor')} />
                    </StyledMenuItem>
                ) : null,
                <DisableEnableSection
                    key="disable-section"
                    isDisabled={props.section.isDisabled}
                    id={props.section.id}
                    onClose={handleClose}
                />
            );
        } else {
            content.push(
                <AddNewDishItem
                    key="add-dish-item"
                    sectionId={props.section.id}
                    sections={props.menu.sections}
                    close={handleClose}
                    forThreeDotsMenu={true}
                    dailyMenuId=""
                />,
                <StyledMenuItem onClick={onChooseExistingClick} key="chose-dish-item">
                    <FormatListBulletedIcon
                        fontSize="small"
                        color="disabled"
                        style={{ marginRight: 23 }}
                    />
                    <ListItemText primary={i18n.t('common.chooseExisting')} />
                </StyledMenuItem>,
                <RenameSection
                    key="rename-section"
                    sectionName={props.section.name}
                    sectionId={props.section.id}
                    close={handleClose}
                    sections={props.menu.sections.reduce((result: string[], current) => {
                        if (current.name !== props.section.name) {
                            result.push(current.name.toUpperCase());
                        }
                        return result;
                    }, [])}
                />,
                currentRestaurant?.currentPosition === Positions.MANAGER ? (
                    <StyledMenuItem onClick={handleColorSelector} key="set-color">
                        <PaletteIcon
                            fontSize="small"
                            color="disabled"
                            style={{ marginRight: 23 }}
                        />
                        <ListItemText primary={i18n.t('common.changeColor')} />
                    </StyledMenuItem>
                ) : null,
                <DeleteMenuItem
                    onDeleteClick={() => setShowConfirmation(true)}
                    key="delete-dish-item"
                />
            );
        }

        return content;
    }

    return (
        <div>
            <IconButton aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon style={{ color: theme.palette.common.white }} />
            </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {prepareContent()}
            </StyledMenu>
            <PopupConfirmation
                open={showConfirmation}
                close={() => setShowConfirmation(false)}
                action={props.onDeleteSectionClick}
                title={i18n.t('common.deleteSection')}
                description={i18n.t('confirmation.deleteSection')}
                activeBtn={i18n.t('button.delete')}
            />
            <ColorSelector
                isOpen={showColorSelector}
                onClose={() => setShowColorSelector(false)}
                value={props.color}
                onChange={props.onChangeColor}
                onSave={props.onSaveColor}
                onCancel={handleColorReset}
            />
        </div>
    );
}
