import React from 'react';

import i18n from 'i18n-js';
import { Form, Formik, getIn } from 'formik';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { TextField, Typography, DialogActions } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { DishData } from 'store/menuDetailsStore/reducer';
import { useStyles } from '../styles';

interface ChooseExistingProps {
    isVisible: boolean;
    onClose: () => void;
    onAdd: (sectionId: string, dishId: string, dish: DishData) => void;
    sections: any[];
    dishList: any[];
    sectionId: string;
}

export default function ChooseExistingModal(props: ChooseExistingProps) {
    const classes = useStyles();

    return (
        <Dialog open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6" align="left" className={classes.chooseExistingTitle}>
                    {i18n.t('common.chooseExisting')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        section: props.sections.find((x) => x.id === props.sectionId),
                        dish: null,
                    }}
                    onSubmit={(values, { resetForm }) => {
                        const { section, dish } = values;
                        if (section && dish) {
                            // @ts-ignore
                            onAdd(section.id, dish.id, dish);
                            resetForm();
                        }
                    }}
                >
                    {({ touched, values, setFieldValue, submitForm }) => (
                        <Form className={classes.chooseExistingContainer}>
                            <>
                                <Autocomplete
                                    id="section"
                                    options={props.sections}
                                    getOptionLabel={(option) => option.name}
                                    value={values.section}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(e: object, value: any | null) => {
                                        setFieldValue('section', value);
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                name="section"
                                                label={i18n.t('common.section')}
                                                variant="filled"
                                                fullWidth
                                                error={
                                                    values.section === null &&
                                                    getIn(touched, 'section')
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </>
                            <br />
                            <>
                                <Autocomplete
                                    id="dish"
                                    options={props.dishList}
                                    getOptionLabel={(option) => option.name}
                                    value={values.dish}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionDisabled={(option) => option.isDisabled}
                                    onChange={(e: object, value: any | null) => {
                                        setFieldValue('dish', value);
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                name="dish"
                                                label={i18n.t('common.dish')}
                                                variant="filled"
                                                fullWidth
                                                error={
                                                    values.dish === null && getIn(touched, 'dish')
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </>
                            <DialogActions>
                                <Button type="button" color="primary" onClick={props.onClose}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button onClick={submitForm} variant="contained" color="primary">
                                    {i18n.t('common.choose')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
