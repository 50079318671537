import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import { DishPreviewItem } from './components';
import { ThreeDotsSectionMenu } from './components';
import { theme } from 'config/theme';
import { Roles, MenuType, OrderDishStatus } from 'constants/enums';
import { ApplicationState } from 'store';
import { actionCreators, OrderItem } from 'store/ordersStore';
import menuDetailsStore from 'store/menuDetailsStore';
import { priceStyleHelper } from 'helpers/helperFunctions';
import { Text } from 'components/shared';
import { actionCreators as ingredientsActions } from 'store/ingredientsStore';
import { useStyles } from './styles';
import { DishData } from 'store/menuDetailsStore/reducer';

export interface MenuSectionsProps {
    sectionId: string;
    isDisabled: boolean;
    onChooseExisting: () => void;
    onDishEdit: (dishId: string) => void;
    isModal?: boolean;
}

export function MenuSectionsTable(props: MenuSectionsProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user);
    const menu = useSelector((state: ApplicationState) => state.menuDetails.menu);
    const order = useSelector((state: ApplicationState) => state.orders.orderDetails);
    const section = useSelector(
        (state: ApplicationState) =>
            state.menuDetails.menu.sections.find((x) => x.id === props.sectionId)!
    );
    const lang = useSelector((state: ApplicationState) => state.global.language);
    const [expanded, setExpanded] = useState<boolean>(!!section.dishes?.length);
    const [selectedDishesIds, setSelectedDishesIds] = useState<string[]>([]);
    const [itemCount, setItemCount] = useState(0);
    const [color, setColor] = useState(section.color || theme.palette.secondary.light);

    const [renderDishes, setRenderDishes] = useState([...section.dishes]);

    const draggedDish = useRef<DishData | null>(null);
    const [draggedDishId, setDraggedDishId] = useState('');

    function onDragStart(dish: DishData) {
        setDraggedDishId(dish.id);
        draggedDish.current = dish;
    }

    function onDragEnter(dish: DishData) {
        if (dish.id !== draggedDish?.current?.id) {
            const tempDishes = [...renderDishes];
            const removedIndex = tempDishes.indexOf(draggedDish?.current!);
            if (removedIndex < 0) {
                return;
            }
            const removeDish = tempDishes.splice(removedIndex, 1);
            const targetDishIndex = tempDishes.indexOf(dish);
            if (removedIndex === targetDishIndex) {
                if (removedIndex === 0 && targetDishIndex === 0) {
                    tempDishes.splice(targetDishIndex + 1, 0, removeDish[0]);
                } else if (removedIndex === 1 && targetDishIndex === 0) {
                    tempDishes.unshift(removeDish[0]);
                } else {
                    tempDishes.splice(targetDishIndex + 1, 0, removeDish[0]);
                }
            } else if (removedIndex > targetDishIndex) {
                tempDishes.splice(targetDishIndex, 0, removeDish[0]);
            } else {
                tempDishes.splice(targetDishIndex + 1, 0, removeDish[0]);
            }

            setRenderDishes(tempDishes);
        }
    }

    function onDragEnd() {
        if (
            section.dishes.indexOf(draggedDish.current!) !==
            renderDishes.indexOf(draggedDish.current!)
        ) {
            dispatch(
                menuDetailsStore.actionCreators.reorderDishes(
                    section.id,
                    renderDishes.map((x) => x.id),
                    !!section.price
                )
            );
        }
        setDraggedDishId('');
        draggedDish.current = null;
    }

    useEffect(() => {
        checkIfExpanded();
        dispatch(ingredientsActions.getAllergies(lang));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRenderDishes([...section.dishes]);
    }, [JSON.stringify(section.dishes)]);

    function checkIfExpanded() {
        let isExpanded = localStorage.getItem(`@expanded-${section.id}`);
        if (isExpanded) {
            setExpanded(isExpanded === '1');
        }
    }

    function handleChange(event: React.ChangeEvent<{}>) {
        event.stopPropagation();
        if (props.isModal && !section.isDisabled && !!section.price) {
            setSelectedDishesIds(section.dishes.map((x) => x.id));
            setExpanded(!expanded);
        } else {
            setExpanded(!expanded);
            localStorage.setItem(`@expanded-${section.id}`, (expanded ? 0 : 1).toString());
        }
    }

    function selectDishes(id: string) {
        if (!!section.price) {
            setSelectedDishesIds(section.dishes.map((x) => x.id));
        } else {
            setSelectedDishesIds([id]);
        }
    }

    function deleteSection() {
        dispatch(
            menuDetailsStore.actionCreators.deleteSection({
                menuId: menu.id,
                sectionId: props.sectionId,
            })
        );
    }

    function handleAmount(e: any, decrease: boolean = false) {
        e.stopPropagation();
        const item: OrderItem = {
            price: section.price!,
            name: section.name,
            id: section.id,
            //uniqueId: helperFunctions.getRandomString(),
            amount: 1,
            shortDescription: '',
            orderId: order.id,
            isReady: false,
            orderNumber: order.orderNumber,
            comment: order.comment,
            service: order.service,
            ingredients: [],
            status: OrderDishStatus.None,
            categoryId: '',
            categoryName: '',
            dailyMenuId: section.id,
            dishes: [...section.dishes],
        };
        setItemCount(decrease ? (itemCount > 0 ? itemCount - 1 : 0) : itemCount + 1);
        return dispatch(actionCreators.addItemToEditedOrder(item, decrease));
    }

    function prepareAmountCounter() {
        return (
            <div className={classes.itemAmountComponent}>
                <div className={classes.itemAmountAction} onClick={(e) => handleAmount(e, true)}>
                    -
                </div>
                <div className={classes.itemAmountCount}>{itemCount}</div>
                <div className={classes.itemAmountAction} onClick={(e) => handleAmount(e, false)}>
                    +
                </div>
            </div>
        );
    }

    function saveSectionColor() {
        dispatch(
            menuDetailsStore.actionCreators.setSectionColor(props.sectionId, !!section.price, color)
        );
    }

    function resetSectionColor() {
        setColor(section.color);
    }

    return (
        <Accordion
            square
            expanded={expanded}
            onChange={handleChange}
            className={classes.sectionManagementRoot}
        >
            <AccordionSummary
                style={{
                    backgroundColor: color,
                    opacity: props.isDisabled ? 0.4 : 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: theme.palette.common.white,
                }}
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <Text text={section.name} maxLength={50} centered />
                <div className={classes.rightSideContainer}>
                    {menu.type === MenuType.Daily && (
                        <label htmlFor="" className={classes.menuLabel}>
                            {i18n.t('common.price')}:
                            <div>
                                <span className={classes.chf}>
                                    {i18n.t('common.currencies.chf')}
                                </span>
                                <span className={classes.currencyText}>
                                    {priceStyleHelper(section.price, true)}
                                </span>
                            </div>
                        </label>
                    )}
                    {props.isModal &&
                        !section.isDisabled &&
                        !!section.price &&
                        !!section.dishes.length &&
                        section.dishes.every((x) => selectedDishesIds.includes(x.id)) &&
                        prepareAmountCounter()}
                    {user.role === Roles.OWNER && (
                        <ThreeDotsSectionMenu
                            menu={menu}
                            section={section}
                            onDeleteSectionClick={deleteSection}
                            onChooseExisting={props.onChooseExisting}
                            color={color}
                            onChangeColor={(color: string) => setColor(color)}
                            onSaveColor={saveSectionColor}
                            onResetColor={resetSectionColor}
                        />
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {renderDishes.length > 0 &&
                    renderDishes.map((x) => (
                        <div
                            key={x.id}
                            draggable={user.role === Roles.OWNER}
                            onDragStart={(e) => {
                                e.stopPropagation();
                                onDragStart(x);
                            }}
                            onDrag={(e) => e.stopPropagation()}
                            onDragEnter={() => onDragEnter(x)}
                            onDragEnd={onDragEnd}
                            onDragOver={(e) => e.preventDefault()}
                            style={{
                                opacity: x.id === draggedDishId ? 0.1 : 1,
                            }}
                        >
                            <DishPreviewItem
                                dish={x}
                                id={x.id}
                                /* onItemDelete={() => deleteDish(x.id)}
                            onItemEdit={() => props.onDishEdit(x.id)}
                            onItemDisable={() => disableDish(x.id)} */
                                key={x.id}
                                /* createDishAction={MenuDetailsStore.actionCreators.addNewItem}
                            updateDishAction={updateDish} */
                                isModal={props.isModal}
                                isSelected={selectedDishesIds.includes(x.id)}
                                setSelected={selectDishes}
                                isDailyMenuDish={!!section.price}
                                dailyMenuId={!!section.price ? section.id : undefined}
                                isDisabled={section.isDisabled}
                                sectionId={section.id}
                                color={section.color !== color ? color : undefined}
                            />
                        </div>
                    ))}
            </AccordionDetails>
        </Accordion>
    );
}
