import { Action, Reducer } from 'redux';

import agent from 'api/agent';
import { IngredientModel, UpdateCategoryModel, UpdateIngredientModel } from 'api/models';

import { AppThunkAction } from './index';
import globalStore from './globalStore';
import { GlobalAction } from './globalStore/actions';

interface GetIngredients {
    type: 'GET_INGREDIENTS_START';
}

interface GetIngredientsSuccess {
    type: 'GET_INGREDIENTS_SUCCESS';
    data: any;
    count: number;
}

interface UpdateIngredient {
    type: 'UPDATE_INGREDIENT_START';
}

interface UpdateIngredientSuccess {
    type: 'UPDATE_INGREDIENT_SUCCESS';
    data: UpdateIngredientModel;
}

interface CreateIngredient {
    type: 'CREATE_INGREDIENT_START';
}

interface CreateIngredientSuccess {
    type: 'CREATE_INGREDIENT_SUCCESS';
    data: IngredientModel;
}

interface CreateCategorySuccess {
    type: 'CREATE_INGREDIENT_CATEGORY_SUCCESS';
    data: any;
}

interface UpdateCategorySuccess {
    type: 'UPDATE_INGREDIENT_CATEGORY_SUCCESS';
    data: any;
}

interface GetCategoriesSuccess {
    type: 'GET_INGREDIENT_CATEGORIES_SUCCESS';
    data: any;
    count: number;
}

interface DeleteCategorySuccess {
    type: 'DELETE_INGREDIENT_CATEGORY_SUCCESS';
    id: string;
}

interface DisableCategorySuccess {
    type: 'DISABLE_INGREDIENT_CATEGORY_SUCCESS';
    id: string;
}

interface DisableCategorySuccess {
    type: 'DISABLE_INGREDIENT_CATEGORY_SUCCESS';
    id: string;
}

interface GetAllergiesStart {
    type: 'GET_ALLERGIES_START';
}

interface GetAllergiesSuccess {
    type: 'GET_ALLERGIES_SUCCESS';
    allergies: Allergen[];
}

interface GetAllergiesError {
    type: 'GET_ALLERGIES_ERROR';
}

export type IngredientsAction =
    | GlobalAction
    | GetIngredients
    | GetIngredientsSuccess
    | UpdateIngredient
    | UpdateIngredientSuccess
    | CreateIngredient
    | CreateIngredientSuccess
    | GetCategoriesSuccess
    | CreateCategorySuccess
    | UpdateCategorySuccess
    | DeleteCategorySuccess
    | DisableCategorySuccess
    | GetAllergiesStart
    | GetAllergiesSuccess
    | GetAllergiesError;

export const actionCreators = {
    getIngredients:
        (
            lang: 'en' | 'de' = 'en',
            page = 0,
            limit = 13,
            getAll = false
        ): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_INGREDIENTS_START',
            });
            agent.Ingredients.GetIngredients(lang, page, limit, getAll).then((res) => {
                dispatch({
                    type: 'GET_INGREDIENTS_SUCCESS',
                    data: res.list,
                    count: res.count,
                });
            });
        },
    updateIngredient:
        (model: UpdateIngredientModel): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_INGREDIENT_START',
            });
            agent.Ingredients.UpdateIngredient(model).then((res) => {
                // actionCreators.getIngredients()(dispatch);
                dispatch({
                    type: 'UPDATE_INGREDIENT_SUCCESS',
                    data: model,
                });
            });
        },
    createIngredient:
        (model: IngredientModel): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_INGREDIENT_START',
            });
            agent.Ingredients.CreateIngredient(model).then((res) => {
                dispatch({
                    type: 'CREATE_INGREDIENT_SUCCESS',
                    data: res,
                });
            });
        },
    getCategories:
        (lang: 'en' | 'de' = 'en', page = 0, limit = 13): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Ingredients.GetCategories(lang, page, limit).then((res) => {
                dispatch({
                    type: 'GET_INGREDIENT_CATEGORIES_SUCCESS',
                    data: res.list,
                    count: res.count,
                });
            });
        },
    createCategory:
        (name: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Ingredients.CreateCategory(name)
                .then((res) => {
                    dispatch({
                        type: 'CREATE_INGREDIENT_CATEGORY_SUCCESS',
                        data: res,
                    });
                })
                .catch(console.log);
        },
    updateCategory:
        (model: UpdateCategoryModel): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Ingredients.UpdateCategory(model).then((res) => {
                dispatch({
                    type: 'UPDATE_INGREDIENT_CATEGORY_SUCCESS',
                    data: res,
                });
            });
        },
    deleteCategory:
        (id: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Ingredients.DeleteCategory(id).then((res) => {
                dispatch({
                    type: 'DELETE_INGREDIENT_CATEGORY_SUCCESS',
                    id,
                });
            });
        },
    disableEnableCategory:
        (id: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Ingredients.DisableEnable(id).then((res) => {
                dispatch({
                    type: 'DISABLE_INGREDIENT_CATEGORY_SUCCESS',
                    id,
                });
            });
        },
    getAllergies:
        (lang: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ALLERGIES_START',
            });
            agent.Ingredients.GetAllergiens(lang)
                .then((res) => {
                    dispatch({
                        type: 'GET_ALLERGIES_SUCCESS',
                        allergies: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_ALLERGIES_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                });
        },
};

export interface IngredientCategoryData {
    id: string;
    name: string;
    ingredients: any[];
    isDisabled: boolean;
}

export interface Allergen {
    id: string;
    name: string;
    icon: string;
}

export interface IngredientsState {
    ingredients: any[];
    ingredientsCount: number;
    categories: IngredientCategoryData[];
    categoriesCount: number;
    allergies: Allergen[];
}

//REDUCER
const initialState = {
    ingredients: [],
    ingredientsCount: 13,
    categories: [],
    categoriesCount: 13,
    allergies: [],
};

export const reducer: Reducer<IngredientsState> = (
    state: IngredientsState | undefined,
    incomingAction: Action
): IngredientsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as IngredientsAction;
    switch (action.type) {
        case 'GET_INGREDIENTS_SUCCESS': {
            return {
                ...state,
                ingredients: action.data,
                ingredientsCount: action.count,
            };
        }
        case 'CREATE_INGREDIENT_SUCCESS': {
            const newIngredients = [...state.ingredients, action.data];
            return {
                ...state,
                ingredients: newIngredients,
            };
        }
        case 'UPDATE_INGREDIENT_SUCCESS': {
            const indexOfIngredient = state.ingredients.findIndex(
                (val) => val.id === action.data.id
            );
            const newIngredients = [...state.ingredients];
            newIngredients[indexOfIngredient] = action.data;
            return {
                ...state,
                ingredients: newIngredients,
            };
        }
        case 'GET_INGREDIENT_CATEGORIES_SUCCESS': {
            return {
                ...state,
                categories: action.data,
                categoriesCount: action.count,
            };
        }
        case 'CREATE_INGREDIENT_CATEGORY_SUCCESS': {
            return {
                ...state,
                categories: [...state.categories, action.data],
            };
        }
        case 'UPDATE_INGREDIENT_CATEGORY_SUCCESS': {
            const indexOfCategory = state.categories.findIndex((val) => val.id === action.data.id);
            const newCategories = [...state.categories];
            newCategories[indexOfCategory] = {
                ...newCategories[indexOfCategory],
                name: action.data.name,
            };
            return {
                ...state,
                categories: newCategories,
            };
        }
        case 'DELETE_INGREDIENT_CATEGORY_SUCCESS': {
            return {
                ...state,
                categories: state.categories.filter(
                    (eachCategory) => eachCategory.id !== action.id
                ),
            };
        }
        case 'DISABLE_INGREDIENT_CATEGORY_SUCCESS': {
            const indexOfCategory = state.categories.findIndex((val) => val.id === action.id);
            const newCategories = [...state.categories];
            newCategories[indexOfCategory].isDisabled = !newCategories[indexOfCategory].isDisabled;
            return {
                ...state,
                categories: newCategories,
            };
        }
        case 'GET_ALLERGIES_SUCCESS': {
            return { ...state, allergies: action.allergies };
        }
        default:
            return state;
    }
};
