import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import i18n from 'i18n-js';

import agent from 'api/agent';
import { RestaurantPaymentDetailsModel } from 'api/models';
import { RestaurantCreateModel } from '../store/restaurantsStore';
import { RoleData } from './rolesStore';
import { RestaurantUserData } from './usersStore';
import { GlobalAction } from './globalStore/actions';
import globalStore from 'store/globalStore';

//STATE
export interface RestaurantDetailsState {
    restaurant: RestaurantDetails;
    restaurantInformation: RestaurantDetails;
    cuisineTypes: CuisineData[];
    qrCodes: QRData[];
    qrCodesLoading: boolean;
    responsibleUsers: ResponsibleUser[];
}

export interface CuisineData {
    id: string;
    name: string;
    inUse: number;
}

export interface RestaurantContactPerson {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isEnabled: boolean;
    restaurants: RestaurantUserData[];
    roles: RoleData[];
}

export interface RestaurantDetails {
    id: string;
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    phoneNumber: string;
    email: string;
    website: string;
    latitude: number;
    longitude: number;
    contactPersons: RestaurantContactPerson[];
    logoUrl?: string;
    photoUrl?: string;
    hasHomeDelivery: boolean;
    hasTakeAway: boolean;
    hasTakeOut: boolean;
    hasOnSite: boolean;
    percentageForVorort?: number;
    percentageForTakeAway?: number;
    percentageForHomeDelivery?: number;
    paymentDetails: PaymentDetails;
    timeZone: string;
    openHours?: any[];
    qrCodesName: string;
}

export interface QRData {
    id?: string;
    name: string;
    service: string;
    url: string;
    area: string;
    areaId?: string;
}

export interface ResponsibleUser {
    id: string;
    position: Position;
    name: string;
}

export interface PaymentDetails extends RestaurantPaymentDetailsModel {}

//ACTIONS
interface RestaurantDetailsGetStartAction {
    type: 'RESTAURANT_DETAILS_GET_START';
}

interface RestaurantDetailsGetSuccessAction {
    type: 'RESTAURANT_DETAILS_GET_SUCCESS';
    restaurant: RestaurantDetails;
}

interface RestaurantDetailsGetErrorAction {
    type: 'RESTAURANT_DETAILS_GET_ERROR';
}

interface RestaurantDetailsUpdateStartAction {
    type: 'RESTAURANT_DETAILS_UPDATE_START';
}

interface RestaurantDetailsUpdateSuccessAction {
    type: 'RESTAURANT_DETAILS_UPDATE_SUCCESS';
    restaurant: RestaurantDetails;
}

interface RestaurantDetailsUpdateErrorAction {
    type: 'RESTAURANT_DETAILS_UPDATE_ERROR';
}

interface GetRestaurantInformationStart {
    type: 'GET_RESTAURANT_INFORMATION_START';
}

interface GetRestaurantInformationSuccess {
    type: 'GET_RESTAURANT_INFORMATION_SUCCESS';
    restaurantInformation: RestaurantDetails;
}

interface GetRestaurantInformationError {
    type: 'GET_RESTAURANT_INFORMATION_ERROR';
}

interface UpdateRestaurantInformationStart {
    type: 'UPDATE_RESTAURANT_INFORMATION_START';
}

interface UpdateRestaurantInformationSuccess {
    type: 'UPDATE_RESTAURANT_INFORMATION_SUCCESS';
    restaurantInformation: RestaurantDetails;
}

interface UpdateRestaurantInformationError {
    type: 'UPDATE_RESTAURANT_INFORMATION_ERROR';
}

interface GetCuisineTypesStart {
    type: 'GET_CUISINE_TYPES_START';
}

interface GetCuisineTypesSuccess {
    type: 'GET_CUISINE_TYPES_SUCCESS';
    data: CuisineData[];
}

interface GetCuisineTypesError {
    type: 'GET_CUISINE_TYPES_ERROR';
}

interface GetQrCodesStart {
    type: 'GET_QR_CODES_START';
}

interface GetQrCodesSuccess {
    type: 'GET_QR_CODES_SUCCESS';
    data: QRData[];
}

interface GetQrCodesError {
    type: 'GET_QR_CODES_ERROR';
}

interface CreateQrCodeStart {
    type: 'CREATE_QR_CODE_START';
}

interface CreateQrCodeSuccess {
    type: 'CREATE_QR_CODE_SUCCESS';
}

interface CreateQrCodeError {
    type: 'CREATE_QR_CODE_ERROR';
}

interface UpdateQrCodeStart {
    type: 'UPDATE_QR_CODE_START';
}

interface UpdateQrCodeSuccess {
    type: 'UPDATE_QR_CODE_SUCCESS';
    data: QRData;
}

interface UpdateQrCodeError {
    type: 'UPDATE_QR_CODE_ERROR';
}

interface DeleteQrCodeStart {
    type: 'DELETE_QR_CODE_START';
}

interface DeleteQrCodeSuccess {
    type: 'DELETE_QR_CODE_SUCCESS';
    data: string[];
}

interface DeleteQrCodeError {
    type: 'DELETE_QR_CODE_ERROR';
}

interface UpdateContactPersons {
    type: 'UPDATE_CONTACT_PERSONS';
    users: RestaurantContactPerson[];
}

interface UpdatePaymentDetailsStart {
    type: 'UPDATE_PAYMENT_DETAILS_START';
}

interface UpdatePaymentDetailsSuccess {
    type: 'UPDATE_PAYMENT_DETAILS_SUCCESS';
    data: PaymentDetails;
}

interface UpdatePaymentDetailsError {
    type: 'UPDATE_PAYMENT_DETAILS_ERROR';
}

interface UpdateQRCodesNameStart {
    type: 'UPDATE_QR_CODES_NAME_START';
}

interface UpdateQRCodesNameSuccess {
    type: 'UPDATE_QR_CODES_NAME_SUCCESS';
    data: string;
}

interface UpdateQRCodesNameError {
    type: 'UPDATE_QR_CODES_NAME_ERROR';
}

interface GetRestaurantResponsibleUsersStart {
    type: 'GET_RESTAURANT_RESPONSIBLE_USERS_START';
}

interface GetRestaurantResponsibleUsersSuccess {
    type: 'GET_RESTAURANT_RESPONSIBLE_USERS_SUCCESS';
    data: ResponsibleUser[];
}

interface GetRestaurantResponsibleUsersError {
    type: 'GET_RESTAURANT_RESPONSIBLE_USERS_ERROR';
}

//ACTION TYPES
type RestaurantDetailsAction =
    | RestaurantDetailsGetStartAction
    | RestaurantDetailsGetSuccessAction
    | RestaurantDetailsGetErrorAction
    | RestaurantDetailsUpdateStartAction
    | RestaurantDetailsUpdateSuccessAction
    | RestaurantDetailsUpdateErrorAction
    | GetRestaurantInformationStart
    | GetRestaurantInformationSuccess
    | GetRestaurantInformationError
    | UpdateRestaurantInformationStart
    | UpdateRestaurantInformationSuccess
    | UpdateRestaurantInformationError
    | GetCuisineTypesStart
    | GetCuisineTypesSuccess
    | GetCuisineTypesError
    | GetQrCodesStart
    | GetQrCodesSuccess
    | GetQrCodesError
    | CreateQrCodeStart
    | CreateQrCodeSuccess
    | CreateQrCodeError
    | UpdateQrCodeStart
    | UpdateQrCodeSuccess
    | UpdateQrCodeError
    | DeleteQrCodeStart
    | DeleteQrCodeSuccess
    | DeleteQrCodeError
    | UpdateContactPersons
    | UpdatePaymentDetailsStart
    | UpdatePaymentDetailsSuccess
    | UpdatePaymentDetailsError
    | UpdateQRCodesNameStart
    | UpdateQRCodesNameSuccess
    | UpdateQRCodesNameError
    | GlobalAction
    | GetRestaurantResponsibleUsersStart
    | GetRestaurantResponsibleUsersSuccess
    | GetRestaurantResponsibleUsersError;

//ACTION CREATORS
export const actionCreators = {
    getRestaurantDetails:
        (id: string): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANT_DETAILS_GET_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.GetDetails(id)
                .then((response) =>
                    dispatch({
                        type: 'RESTAURANT_DETAILS_GET_SUCCESS',
                        restaurant: response,
                    })
                )
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANT_DETAILS_GET_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    updateRestaurantDetails:
        (id: string, restaurant: RestaurantCreateModel): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANT_DETAILS_UPDATE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.Update(id, restaurant)
                .then((response) => {
                    dispatch({
                        type: 'RESTAURANT_DETAILS_UPDATE_SUCCESS',
                        restaurant: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANT_DETAILS_UPDATE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getRestaurantInformation: (): AppThunkAction<RestaurantDetailsAction> => (dispatch) => {
        dispatch({
            type: 'GET_RESTAURANT_INFORMATION_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.Restaurants.GetRestaurantInformation()
            .then((response) => {
                dispatch({
                    type: 'GET_RESTAURANT_INFORMATION_SUCCESS',
                    restaurantInformation: response,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'GET_RESTAURANT_INFORMATION_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    updateRestaurantInformation:
        (model: RestaurantDetails): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({ type: 'UPDATE_RESTAURANT_INFORMATION_START' });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdateRestaurantInformation(model)
                .then((response: any) => {
                    dispatch({
                        type: 'UPDATE_RESTAURANT_INFORMATION_SUCCESS',
                        restaurantInformation: response.data,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_RESTAURANT_INFORMATION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getCuisineTypes: (): AppThunkAction<RestaurantDetailsAction> => (dispatch) => {
        dispatch({
            type: 'GET_CUISINE_TYPES_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.Restaurants.GetCuisineTypes()
            .then((response) => {
                dispatch({
                    type: 'GET_CUISINE_TYPES_SUCCESS',
                    data: response.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'GET_CUISINE_TYPES_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    getQrCodes:
        (params = {}): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_QR_CODES_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.GetQrs(params)
                .then((response) => {
                    dispatch({
                        type: 'GET_QR_CODES_SUCCESS',
                        data: response,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_QR_CODES_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    createQrCode:
        (qr: QRData, params: {}): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_QR_CODE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.CreateQrCode(qr, params)
                .then((response) => {
                    // @ts-ignore
                    actionCreators.getQrCodes(params)(dispatch);
                    dispatch({
                        type: 'CREATE_QR_CODE_SUCCESS',
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'CREATE_QR_CODE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateQrCode:
        (qr: QRData): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_QR_CODE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdateQrCode(qr)
                .then(() => {
                    dispatch({
                        type: 'UPDATE_QR_CODE_SUCCESS',
                        data: qr,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'UPDATE_QR_CODE_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    deleteQrCode:
        (qrs: string[]): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'DELETE_QR_CODE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.DeleteQrCode(qrs)
                .then(() => {
                    dispatch({
                        type: 'DELETE_QR_CODE_SUCCESS',
                        data: qrs,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DELETE_QR_CODE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateContactPersons:
        (users: RestaurantContactPerson[]): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_CONTACT_PERSONS',
                users: users,
            });
        },
    updatePaymentDetails:
        (model: RestaurantPaymentDetailsModel): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_PAYMENT_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdatePaymentDetails(model)
                .then((response) => {
                    dispatch({
                        type: 'UPDATE_PAYMENT_DETAILS_SUCCESS',
                        data: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_PAYMENT_DETAILS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateQrCodesName:
        (name: string): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_QR_CODES_NAME_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdateQrCodesName(name)
                .then((response) => {
                    dispatch({
                        type: 'UPDATE_QR_CODES_NAME_SUCCESS',
                        data: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_QR_CODES_NAME_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getRestaurantResponsibleUsers: (): AppThunkAction<RestaurantDetailsAction> => (dispatch) => {
        dispatch({
            type: 'GET_RESTAURANT_RESPONSIBLE_USERS_START',
        });
        agent.Users.GetResponsibleUsers()
            .then((response) => {
                dispatch({
                    type: 'GET_RESTAURANT_RESPONSIBLE_USERS_SUCCESS',
                    data: response,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'GET_RESTAURANT_RESPONSIBLE_USERS_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
};

//REDUCER
const initialState: RestaurantDetailsState = {
    restaurant: {
        id: '',
        name: '',
        addressLine: '',
        zip: '',
        city: '',
        phoneNumber: '',
        email: '',
        website: '',
        latitude: 0,
        longitude: 0,
        contactPersons: [],
        hasHomeDelivery: false,
        hasTakeAway: false,
        hasTakeOut: false,
        hasOnSite: false,
        paymentDetails: {
            firmName: '',
            firstName: '',
            lastName: '',
            address: '',
            zip: '',
            city: '',
            accountNumber: '',
            iban: '',
            vat: '',
            tax: 0,
            currency: '',
            thanksMessage: '',
        },
        timeZone: '',
        qrCodesName: '',
    },
    restaurantInformation: {
        name: '',
        id: '',
        addressLine: '',
        zip: '',
        city: '',
        phoneNumber: '',
        email: '',
        website: '',
        latitude: 0,
        longitude: 0,
        contactPersons: [],
        hasHomeDelivery: false,
        hasTakeAway: false,
        hasTakeOut: false,
        hasOnSite: false,
        paymentDetails: {
            firmName: '',
            firstName: '',
            lastName: '',
            address: '',
            zip: '',
            city: '',
            accountNumber: '',
            iban: '',
            vat: '',
            tax: 0,
            currency: '',
            thanksMessage: '',
        },
        timeZone: '',
        qrCodesName: '',
    },
    cuisineTypes: [],
    qrCodes: [],
    qrCodesLoading: true,
    responsibleUsers: [],
};

export const reducer: Reducer<RestaurantDetailsState> = (
    state: RestaurantDetailsState | undefined,
    incomingAction: Action
): RestaurantDetailsState => {
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as RestaurantDetailsAction;

    switch (action.type) {
        case 'RESTAURANT_DETAILS_GET_SUCCESS':
            return {
                ...state,
                restaurant: action.restaurant,
            };
        case 'RESTAURANT_DETAILS_UPDATE_SUCCESS':
            return {
                ...state,
                restaurant: action.restaurant,
            };
        case 'GET_RESTAURANT_INFORMATION_SUCCESS': {
            return {
                ...state,
                restaurantInformation: action.restaurantInformation,
            };
        }
        case 'GET_CUISINE_TYPES_SUCCESS': {
            return {
                ...state,
                cuisineTypes: action.data,
            };
        }
        case 'UPDATE_RESTAURANT_INFORMATION_SUCCESS': {
            return {
                ...state,
                restaurantInformation: action.restaurantInformation,
            };
        }
        case 'GET_QR_CODES_SUCCESS': {
            return {
                ...state,
                qrCodes: action.data,
                qrCodesLoading: false,
            };
        }
        case 'DELETE_QR_CODE_SUCCESS': {
            //@ts-ignore
            const newQrCodes = state.qrCodes.filter((qrCode) => !action.data.includes(qrCode.id));
            return {
                ...state,
                qrCodes: newQrCodes,
            };
        }
        case 'UPDATE_QR_CODE_SUCCESS': {
            const restOfQrs = state.qrCodes.filter((qrCode) => qrCode.id !== action.data.id);
            return {
                ...state,
                qrCodes: [action.data, ...restOfQrs],
            };
        }
        case 'UPDATE_CONTACT_PERSONS': {
            return {
                ...state,
                restaurantInformation: {
                    ...state.restaurantInformation,
                    contactPersons: state.restaurantInformation.contactPersons.map((x) =>
                        action.users.find((y) => y.id === x.id)
                            ? action.users.find((y) => y.id === x.id)!
                            : x
                    ),
                },
            };
        }
        case 'UPDATE_PAYMENT_DETAILS_SUCCESS': {
            return {
                ...state,
                restaurantInformation: {
                    ...state.restaurantInformation,
                    paymentDetails: action.data,
                },
            };
        }
        case 'UPDATE_QR_CODES_NAME_SUCCESS': {
            return {
                ...state,
                restaurantInformation: { ...state.restaurantInformation, qrCodesName: action.data },
            };
        }
        case 'GET_RESTAURANT_RESPONSIBLE_USERS_SUCCESS': {
            return {
                ...state,
                responsibleUsers: action.data,
            };
        }
        default:
            return state;
    }
};
