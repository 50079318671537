import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as AppUser from './userStore';
import { reducer as oidcReducer } from 'redux-oidc';
import { User } from 'oidc-client';
import * as Restaurants from './restaurantsStore';
import * as RestaurantDetails from './restaurantDetailsStore';
import * as Users from './usersStore';
import * as Roles from './rolesStore';
import * as Menus from './menusStore';
import * as Orders from './ordersStore';
import * as Ingredients from './ingredientsStore';
import * as UI from './uiStore';

import categoryStore from './categoryStore';
import { CategoryState } from './categoryStore/reducer';
import financeStore from './financeStore';
import { FinanceState } from './financeStore/reducer';
import globalStore from './globalStore';
import { GlobalState } from './globalStore/reducer';
import menuDetailsStore from './menuDetailsStore';
import { MenuDetailsState } from './menuDetailsStore/reducer';

export const history = createBrowserHistory();

export interface OidcState {
    isLoadingUser: boolean;
    user: User;
}

// The top-level state object
export interface ApplicationState {
    oidic: OidcState;
    router: RouterState;
    user: AppUser.UserState;
    restaurants: Restaurants.RestaurantsState;
    restaurantDetails: RestaurantDetails.RestaurantDetailsState;
    users: Users.UsersState;
    roles: Roles.RolesState;
    menus: Menus.MenusState;
    menuDetails: MenuDetailsState;
    category: CategoryState;
    orders: Orders.OrdersState;
    finance: FinanceState;
    global: GlobalState;
    ingredients: Ingredients.IngredientsState;
    ui: UI.UiState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    router: connectRouter(history),
    oidc: oidcReducer,
    user: AppUser.reducer,
    restaurants: Restaurants.reducer,
    restaurantDetails: RestaurantDetails.reducer,
    users: Users.reducer,
    roles: Roles.reducer,
    menus: Menus.reducer,
    menuDetails: menuDetailsStore.reducer,
    category: categoryStore.reducer,
    orders: Orders.reducer,
    finance: financeStore.reducer,
    global: globalStore.reducer,
    ingredients: Ingredients.reducer,
    ui: UI.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState?: () => ApplicationState): void;
}
