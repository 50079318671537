import React from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import i18n from 'i18n-js';
import {
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    makeStyles,
    createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiDialog-paperWidthXs': {
                width: 'auto',
            },
            '& .MuiDialogActions-root': {
                paddingRight: 22,
                marginTop: 10,
            },
        },
    })
);

interface ColorSelectorProps {
    isOpen: boolean;
    onChange: (value: string) => void;
    onClose: () => void;
    value: string;
    onSave: () => void;
    onCancel: () => void;
}

export default function ColorSelector(props: ColorSelectorProps) {
    const classes = useStyles();
    return (
        <Dialog
            maxWidth="xs"
            open={props.isOpen}
            onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
            className={classes.root}
        >
            <DialogContent>
                <ColorPicker hideControls value={props.value} onChange={props.onChange} />
            </DialogContent>
            <DialogActions>
                <Button type="button" color="primary" onClick={props.onCancel}>
                    {i18n.t('button.cancel')}
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        props.onSave();
                        props.onClose();
                    }}
                >
                    {i18n.t('common.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
