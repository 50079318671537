import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import i18n from 'i18n-js';

import agent from 'api/agent';
import { RestaurantFilterModel } from 'api/models';
import { Positions } from 'constants/enums';
import { GlobalAction } from './globalStore/actions';
import globalStore from './globalStore';

//STATE
export interface RestaurantsState {
    restaurants: RestaurantData[];
    count: number;
}

export interface RestaurantData {
    id: string;
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    logoUrl: string;
    isDisabled?: boolean;
    positions?: Positions[];
    currentPosition?: Positions;
    currentCategory?: string;
}

export interface RestaurantCreateModel {
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    phoneNumber: string;
    email: string;
    website: string;
    longitude: number;
    latitude: number;
}

export interface RestaurantPagedModel {
    list: RestaurantData[];
    count: number;
}

//ACTIONS
interface RestaurantsGetStartAction {
    type: 'RESTAURANTS_GET_START';
}

interface RestaurantsGetSuccessAction {
    type: 'RESTAURANTS_GET_SUCCESS';
    restaurantPagedModel: RestaurantPagedModel;
}

interface RestaurantsGetErrorAction {
    type: 'RESTAURANTS_GET_ERROR';
}

interface RestaurantsCreateStartAction {
    type: 'RESTAURANTS_CREATE_START';
}

interface RestaurantsCreateSuccessAction {
    type: 'RESTAURANTS_CREATE_SUCCESS';
    new: RestaurantData;
}

interface RestaurantsCreateErrorAction {
    type: 'RESTAURANTS_CREATE_ERROR';
}

interface RestaurantSwitchStatusStart {
    type: 'RESTAURANT_SWITCH_STATUS_START';
}

interface RestaurantSwitchStatusSuccess {
    type: 'RESTAURANT_SWITCH_STATUS_SUCCESS';
    data: RestaurantData;
}

interface RestaurantSwitchStatusError {
    type: 'RESTAURANT_SWITCH_STATUS_ERROR';
}

//ACTION TYPES
type RestaurantsAction =
    | RestaurantsGetStartAction
    | RestaurantsGetSuccessAction
    | RestaurantsGetErrorAction
    | RestaurantsCreateStartAction
    | RestaurantsCreateSuccessAction
    | RestaurantsCreateErrorAction
    | RestaurantSwitchStatusStart
    | RestaurantSwitchStatusSuccess
    | RestaurantSwitchStatusError
    | GlobalAction;

//ACTION CREATORS
export const actionCreators = {
    getRestaurants:
        (
            restaurantFilterModel: RestaurantFilterModel | null = null,
            page?: number,
            limit?: number,
            asc?: boolean,
            orderBy?: string
        ): AppThunkAction<RestaurantsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANTS_GET_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.GetList(restaurantFilterModel, page, limit, asc, orderBy)
                .then((response) =>
                    dispatch({ type: 'RESTAURANTS_GET_SUCCESS', restaurantPagedModel: response })
                )
                .catch((e) => {
                    dispatch({ type: 'RESTAURANTS_GET_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    createRestaurant:
        (model: RestaurantCreateModel): AppThunkAction<RestaurantsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANTS_CREATE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.Create(model)
                .then((response) => {
                    dispatch({
                        type: 'RESTAURANTS_CREATE_SUCCESS',
                        new: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANTS_CREATE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    switchRestaurantStatus:
        (restaurant: RestaurantData): AppThunkAction<RestaurantsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANT_SWITCH_STATUS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.DisableRestaurant(restaurant.id)
                .then(() => {
                    restaurant.isDisabled = !restaurant.isDisabled;
                    dispatch({
                        type: 'RESTAURANT_SWITCH_STATUS_SUCCESS',
                        data: restaurant,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANT_SWITCH_STATUS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};

//REDUCER
const initialState: RestaurantsState = {
    restaurants: [],
    count: 0,
};

export const reducer: Reducer<RestaurantsState> = (
    state: RestaurantsState | undefined,
    incomingAction: Action
): RestaurantsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as RestaurantsAction;
    switch (action.type) {
        case 'RESTAURANTS_GET_SUCCESS':
            return {
                ...state,
                restaurants: action.restaurantPagedModel.list,
                count: action.restaurantPagedModel.count,
            };
        case 'RESTAURANTS_CREATE_SUCCESS':
            return {
                ...state,
                restaurants: state.restaurants.concat(action.new),
            };

        case 'RESTAURANT_SWITCH_STATUS_SUCCESS': {
            const selectedRestaurant = action.data;
            const selectedRestaurantIndex = state.restaurants.findIndex(
                (each) => each.id === selectedRestaurant.id
            );
            const newRestaurantsList = [...state.restaurants];

            if (selectedRestaurantIndex !== -1) {
                newRestaurantsList[selectedRestaurantIndex] = selectedRestaurant;
            }

            return {
                ...state,
                restaurants: newRestaurantsList,
            };
        }
        default:
            return state;
    }
};
